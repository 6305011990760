import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './section.css'

const Section = (props) => {
  const [isVisible, setIsVisible] = useState(0)
  return (
    <section className={`section-section ${props.rootClassName} `}>
      <h2 className="section-text10">{props.heading}</h2>
      <div className="section-features">
        <header onClick={() => setIsVisible(0)} className="section-feature1">
          <h3 className="section-text11">{props.heading1}</h3>
          <p className="section-text12">{props.text}</p>
          {isVisible === 0 && <div className="section-container1"></div>}
        </header>
        <header onClick={() => setIsVisible(1)} className="section-feature2">
          <h3 className="section-text13">{props.heading2}</h3>
          <p className="section-text14">{props.text1}</p>
          {isVisible === 1 && <div className="section-container2"></div>}
        </header>
        <header onClick={() => setIsVisible(2)} className="section-feature3">
          <h3 className="section-text15">{props.heading21}</h3>
          <p className="section-text16">{props.text11}</p>
          {isVisible === 2 && <div className="section-container3"></div>}
        </header>
      </div>
      {isVisible === 0 && (
        <div className="section-energie">
          <div className="section-content1">
            <main className="section-main1">
              <h2 className="section-heading1">{props.heading4}</h2>
              <p className="section-paragraph1">
                <span>
                  Warum braucht ein Computer überhaupt Strom? Und wie kommt es
                  dazu, dass auch Webseiten Strom verbrauchen? Woher kommt
                  dieser Strom und wie wird er produziert? Hier erfährst du mehr
                  über die Hintergründe der Industrie und des Energieverbrauchs.
                  Außerdem bekommen Sie Tipps, was Sie selbst tun können.
                </span>
                <br></br>
                <br></br>
                <br></br>
              </p>
            </main>
            <div className="section-explore-more1">
              <a
                href="https://app.seite105.de/hosting"
                target="_blank"
                rel="noreferrer noopener"
                className="section-link1"
              >
                <p className="section-text21">{props.text3}</p>
              </a>
            </div>
          </div>
          <div className="section-image1">
            <img
              alt={props.imageAlt}
              src={props.imageSrc}
              loading="eager"
              className="section-image2"
            />
          </div>
        </div>
      )}
      {isVisible === 1 && (
        <div className="section-datei">
          <div className="section-content2">
            <main className="section-main2">
              <h2 className="section-heading2">{props.heading42}</h2>
              <p className="section-paragraph2">
                Dateien sind äußerst wichtig. Egal, ob Sie eine Website haben
                oder verwalten, egal, welche Technologie Sie verwenden: Das
                Thema Dateien betrifft uns alle. Jeder kann dazu beitragen,
                Dateien effizient zu nutzen. Kleine Tipps, Überlegungen und
                Verbesserungen in Bezug auf Dateien können wirklich große
                Auswirkungen haben und Ihre Website besser machen. Also, denken
                Sie daran, sich um Ihre Dateien zu kümmern!
              </p>
            </main>
            <div className="section-explore-more2">
              <a
                href="https://app.seite105.de/dateien"
                target="_blank"
                rel="noreferrer noopener"
                className="section-link2"
              >
                <p className="section-text22">{props.text32}</p>
              </a>
            </div>
          </div>
          <div className="section-image3">
            <img
              alt={props.imageAlt2}
              src={props.imageSrc2}
              loading="eager"
              className="section-image4"
            />
          </div>
        </div>
      )}
      {isVisible === 2 && (
        <div className="section-design">
          <div className="section-content3">
            <main className="section-main3">
              <h2 className="section-heading3">{props.heading41}</h2>
              <p className="section-paragraph3">
                Gutes Design ist entscheidend, um das Konzept des Nachhaltigen
                Webs ansprechend zu gestalten. Es gibt zahlreiche Ansätze im
                Design, um Nachhaltigkeit effektiv zu vermitteln und das
                Nachhaltige Web attraktiv zu gestalten.
              </p>
            </main>
            <div className="section-explore-more3">
              <a
                href="https://app.seite105.de/mussdas-sein"
                target="_blank"
                rel="noreferrer noopener"
                className="section-link3"
              >
                <p className="section-text23">{props.text31}</p>
              </a>
            </div>
          </div>
          <div className="section-image5">
            <img
              alt={props.imageAlt1}
              src={props.imageSrc1}
              loading="eager"
              className="section-image6"
            />
          </div>
        </div>
      )}
    </section>
  )
}

Section.defaultProps = {
  imageSrc2: '/frame%202-500h.webp',
  imageAlt2: 'image',
  heading41: 'Design',
  imageAlt: 'image',
  heading21: 'Design',
  heading42: 'Datei',
  text: 'Reduzierung des Stromverbrauchs und die Wahl des Anbieters',
  text11: 'Nachhaltigkeit durch Design',
  imageSrc: '/frame%201-200h.webp',
  text1: 'Dateien sind die Medien, die die Last verursachen',
  imageSrc1: '/kausa-500h.webp',
  heading4: 'Energie',
  rootClassName: '',
  heading: 'Nachhaltige Websites, wie das?',
  text31: 'Gleich mal Gucken ->',
  imageAlt1: 'image',
  heading1: 'Energie',
  text32: 'Gleich mal Gucken ->',
  heading2: 'Dateioptimierung',
  text3: 'Gleich mal Gucken ->',
}

Section.propTypes = {
  imageSrc2: PropTypes.string,
  imageAlt2: PropTypes.string,
  heading41: PropTypes.string,
  imageAlt: PropTypes.string,
  heading21: PropTypes.string,
  heading42: PropTypes.string,
  text: PropTypes.string,
  text11: PropTypes.string,
  imageSrc: PropTypes.string,
  text1: PropTypes.string,
  imageSrc1: PropTypes.string,
  heading4: PropTypes.string,
  rootClassName: PropTypes.string,
  heading: PropTypes.string,
  text31: PropTypes.string,
  imageAlt1: PropTypes.string,
  heading1: PropTypes.string,
  text32: PropTypes.string,
  heading2: PropTypes.string,
  text3: PropTypes.string,
}

export default Section
