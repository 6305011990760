import React from 'react'

import { Player } from '@lottiefiles/react-lottie-player'
import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Section from '../components/section'
import Review from '../components/review'
import FAQ from '../components/faq'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container1">
      <Helmet>
        <title>Nachhaltiges Webdesign</title>
        <meta
          name="description"
          content="Nachhaltiges Webdesign. Vorstellung des Kostenlosen Leitfadens für Nachhaltiges Webdesign: Seite105."
        />
        <meta property="og:title" content="Nachhaltiges Webdesign" />
        <meta
          property="og:description"
          content="Nachhaltiges Webdesign. Vorstellung des Kostenlosen Leitfadens für Nachhaltiges Webdesign: Seite105."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/8324cc46-3541-4821-91e1-5c90948348dd/6781bd5e-3dd7-4d9d-b431-eb25327b38ca?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar rootClassName="navbarroot-class-name"></Navbar>
      <section className="home-section1">
        <div id="hero" className="home-hero">
          <div className="home-content1">
            <main className="home-main1">
              <header className="home-header1">
                <h1 className="home-heading1">
                  Das Nachschlagewerk für Nachhaltige Websites
                </h1>
                <span className="home-caption1">
                  Mach doch einfach! — Kostet nix!
                </span>
              </header>
              <div className="home-buttons">
                <a
                  href="https://app.seite105.de"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link1"
                >
                  <div className="home-get-started1 button">
                    <span className="home-text10">Direkt zum Guide</span>
                  </div>
                </a>
                <a href="#inhalte" className="home-link2">
                  <div className="home-get-started2 button">
                    <span className="home-text11">Inhalte</span>
                  </div>
                </a>
              </div>
            </main>
            <div className="home-highlight">
              <div className="home-avatars">
                <img
                  alt="image"
                  src="https://www.maxpraekelt.de/Kollegen/jannes-200w.webp"
                  className="home-image1 avatar"
                />
                <img
                  alt="image"
                  src="https://www.maxpraekelt.de/Kollegen/a-1-200w.webp"
                  className="home-image2 avatar"
                />
              </div>
              <label className="home-caption2">
                consulted by award winning™ Designer*innen
              </label>
            </div>
          </div>
          <div className="home-image3">
            <img
              alt="image"
              sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
              src="/mockdrop_iphone%206%20in%20office-1200w.webp"
              srcSet="/mockdrop_iphone%206%20in%20office-1200w.webp 1200w, /mockdrop_iphone%206%20in%20office-tablet.webp 800w, /mockdrop_iphone%206%20in%20office-mobile.webp 480w"
              className="home-image4"
            />
          </div>
        </div>
      </section>
      <div id="inhalte" className="home-container2">
        <Section rootClassName="sectionroot-class-name"></Section>
      </div>
      <section className="home-section2">
        <header className="home-header2">
          <header className="home-left">
            <span className="section-head">Tempor incididunt</span>
            <h2 className="home-heading2">What users say about us</h2>
          </header>
          <div className="home-right">
            <p className="home-paragraph section-description">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam.
            </p>
          </div>
        </header>
        <main className="home-cards">
          <div className="home-container3">
            <Review rootClassName="reviewroot-class-name"></Review>
            <Review
              quote="“Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.\u2028\u2028Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.”"
              rootClassName="reviewroot-class-name"
            ></Review>
          </div>
          <div className="home-container4">
            <Review
              quote="“Illum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.”"
              rootClassName="reviewroot-class-name"
            ></Review>
            <Review
              quote="“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.”"
              rootClassName="reviewroot-class-name"
            ></Review>
          </div>
          <div className="home-container5">
            <Review
              quote="“Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.”"
              rootClassName="reviewroot-class-name"
            ></Review>
            <Review
              quote="“Doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.”"
              rootClassName="reviewroot-class-name"
            ></Review>
          </div>
        </main>
        <div className="home-view-more">
          <p className="home-text12">View more</p>
        </div>
      </section>
      <div id="OSS" className="home-container6">
        <div className="home-container7">
          <span className="home-section4 section-head">Installation</span>
          <h1 className="home-text13 section-head">OSS</h1>
          <span className="home-text14">
            Die OSS ist ein Modell, das dem Internationalen Raumstation (ISS)
            nachempfunden ist. Sie besteht aus elektronischen Bauteilen und
            Kupferrohren. Mithilfe eines Raspberry Pi Zero 2W erzeugt sie ein
            eigenes WLAN-Netzwerk und einen Webserver. Über diesen Webserver
            kann man eine Offline-Version von Seite 105 anzeigen, selbst ohne
            Internetzugang. Die Energieversorgung erfolgt über Solarzellen und
            eine Pufferbatterie.
          </span>
          <img
            alt="image"
            sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
            src="/osscrop-1200w.webp"
            srcSet="/osscrop-1200w.webp 1200w, /osscrop-tablet.webp 800w, /osscrop-mobile.webp 480w"
            className="home-image5"
          />
        </div>
        <Player
          src="https://storage.googleapis.com/playground-bucket-v2.teleporthq.io/8324cc46-3541-4821-91e1-5c90948348dd/db551ef2-0410-478e-accf-bb64a7d959a2"
          loop="true"
          speed="1"
          autoplay="true"
          background="transparent"
          className="home-lottie-node"
        ></Player>
      </div>
      <section id="FAQ" className="home-section5">
        <header className="home-header3">
          <span className="home-section6 section-head">FAQ</span>
          <h2 className="home-heading3">
            Fragen, die häufig oder überhaupt gestellt werden
          </h2>
        </header>
        <main className="home-accordion">
          <FAQ rootClassName="fa-qroot-class-name"></FAQ>
        </main>
      </section>
      <footer className="home-footer">
        <div className="home-content2">
          <main className="home-main-content">
            <div className="home-content3">
              <header className="home-main2">
                <div className="home-header4">
                  <img
                    alt="image"
                    src="/logo.png.svg"
                    className="home-branding"
                  />
                  <span className="home-text15">Nachhaltiges Webdesign.</span>
                </div>
              </header>
              <header className="home-categories">
                <div className="home-category1">
                  <div className="home-header5">
                    <span className="footer-header">Lösungen</span>
                  </div>
                  <div className="home-links1">
                    <a
                      href="https://maxpraekelt.de"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="home-text17 footer-link"
                    >
                      maxpraekelt.de
                    </a>
                    <a
                      href="https://app.seite105.de"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="home-text18 footer-link"
                    >
                      <span>seite105.de</span>
                      <br></br>
                    </a>
                  </div>
                </div>
                <div className="home-category2">
                  <div className="home-header6">
                    <span className="footer-header">Andere Projekte</span>
                  </div>
                  <div className="home-links2">
                    <a
                      href="https://www.youtube.com/@somewebdesign"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="home-text22 footer-link"
                    >
                      somewebdesign
                    </a>
                  </div>
                </div>
              </header>
            </div>
            <section className="home-copyright1">
              <span className="home-text23">
                © 2024 Braunschweig. All Rights Reserved.
              </span>
            </section>
          </main>
          <main className="home-subscribe">
            <main id="mail" className="home-main3">
              <h1 className="home-heading4">
                Schreiben Sie mir &apos;ne Mail:
              </h1>
              <div className="home-input-field">
                <input
                  type="email"
                  disabled="true"
                  className="home-textinput input"
                />
                <animate-on-reveal
                  animation="wobble"
                  duration="500ms"
                  delay="0s"
                  direction="normal"
                  easing="ease"
                  iteration="1"
                >
                  <a href="mailto:m@xpraekelt.de?subject=Hallo erst mal, ich weiß nicht, ob Sie es schon wissen, aber..:">
                    <div
                      data-thq-animate-on-reveal="true"
                      className="home-buy button"
                    >
                      <span className="home-text24">m@xpraekelt.de</span>
                    </div>
                  </a>
                </animate-on-reveal>
              </div>
            </main>
            <h1 className="home-notice">
              By mailing me you agree with my Terms and Conditions.
            </h1>
          </main>
          <section className="home-copyright2">
            <span className="home-text25">
              © 2024 Braunschweig. All Rights Reserved.
            </span>
          </section>
        </div>
      </footer>
      <div>
        <div className="home-container9">
          <Script
            html={`<script>
    /*
Accordion - Code Embed
*/

/* listenForUrlChangesAccordion() makes sure that if you changes pages inside your app,
the Accordions will still work*/

const listenForUrlChangesAccordion = () => {
      let url = location.href;
      document.body.addEventListener(
        "click",
        () => {
          requestAnimationFrame(() => {
            if (url !== location.href) {
              runAccordionCodeEmbed();
              url = location.href;
            }
          });
        },
        true
      );
    };


const runAccordionCodeEmbed = () => {
    const accordionContainers = document.querySelectorAll('[data-role="accordion-container"]'); // All accordion containers
    const accordionContents = document.querySelectorAll('[data-role="accordion-content"]'); // All accordion content
    const accordionIcons = document.querySelectorAll('[data-role="accordion-icon"]'); // All accordion icons

    accordionContents.forEach((accordionContent) => {
        accordionContent.style.display = "none"; //Hides all accordion contents
    });

    accordionContainers.forEach((accordionContainer, index) => {
        accordionContainer.addEventListener("click", () => {
            accordionContents.forEach((accordionContent) => {
            accordionContent.style.display = "none"; //Hides all accordion contents
            });

            accordionIcons.forEach((accordionIcon) => {
                accordionIcon.style.transform = "rotate(0deg)"; // Resets all icon transforms to 0deg (default)
            });

            accordionContents[index].style.display = "flex"; // Shows accordion content
            accordionIcons[index].style.transform = "rotate(180deg)"; // Rotates accordion icon 180deg
        });
    });
}

runAccordionCodeEmbed()
listenForUrlChangesAccordion()

/*
Here's what the above is doing:
    1. Selects all accordion containers, contents, and icons
    2. Hides all accordion contents
    3. Adds an event listener to each accordion container
    4. When an accordion container is clicked, it:
        - Hides all accordion contents
        - Resets all icon transforms to 0deg (default)
        - Checks if this container has class "accordion-open"
            - If it does, it removes class "accordion-open"
            - If it doesn't, it:
                - Removes class "accordion-open" from all containers
                - Adds class "accordion-open" to this container
                - Shows accordion content
                - Rotates accordion icon 180deg
*/
</script>`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

export default Home
