import React from 'react'

import PropTypes from 'prop-types'

import './faq.css'

const FAQ = (props) => {
  return (
    <div className={`faq-accordion ${props.rootClassName} `}>
      <div
        data-role="accordion-container"
        className="faq-element1 accordion-element"
      >
        <div className="faq-details1">
          <span className="faq-text10">Warum ist das eigentlich React?</span>
          <a
            href="https://youtu.be/v0Ad220QFUc?feature=shared&amp;t=2558"
            data-role="accordion-content"
            target="_blank"
            rel="noreferrer noopener"
            className="faq-link"
          >
            &quot;Sag ich dir doch nicht du Affe.&quot;
          </a>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon10">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="faq-element2 accordion-element"
      >
        <div className="faq-details2">
          <span className="faq-text11">Kostet das was?</span>
          <span data-role="accordion-content" className="faq-text12">
            Nein.
          </span>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon12">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="faq-element3 accordion-element"
      >
        <div className="faq-details3">
          <span className="faq-text13">
            Sollte ich Nachhaltige Websites gestalten?
          </span>
          <span data-role="accordion-content" className="faq-text14">
            Ja.
          </span>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon14">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="faq-element4 accordion-element"
      >
        <div className="faq-details4">
          <span className="faq-text15">
            Welche award winning™ Designer*innen konsultieren bei
            Nachhaltigkeitsfragen Seite105?
          </span>
          <span data-role="accordion-content" className="faq-text16">
            Mindestens Anthony Rush™, manchmal auch jku™.
          </span>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon16">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="faq-element5 accordion-element"
      >
        <div className="faq-details5">
          <span className="faq-text17">
            Ich habe viel Geld keine Ahnung und Nachhaltigkeit ist mir wichtig,
            was soll ich tun?
          </span>
          <span data-role="accordion-content" className="faq-text18">
            Beauftragen Sie mich Ihre Leichen zu entsorgen. John Wick ist
            niemand dagegen.
          </span>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon18">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="faq-element6 accordion-element"
      >
        <div className="faq-details6">
          <span className="faq-text19">
            Sollen Cargosites im Sinne der Nachhaltigkeit genutzt werden?
          </span>
          <span data-role="accordion-content" className="faq-text20">
            Nein. Aber haben Sie schon mal einen Blick auf Kirby geworfen?
          </span>
        </div>
        <div data-role="accordion-icon">
          <svg viewBox="0 0 1024 1024" className="faq-icon20">
            <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
          </svg>
        </div>
      </div>
    </div>
  )
}

FAQ.defaultProps = {
  rootClassName: '',
}

FAQ.propTypes = {
  rootClassName: PropTypes.string,
}

export default FAQ
