import React from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './navbar.css'

const Navbar = (props) => {
  return (
    <nav className={`navbar-navbar ${props.rootClassName} `}>
      <a href="#hero" className="navbar-link1">
        <img
          alt="Planical7012"
          src={props.brandingLogo}
          className="navbar-branding-logo"
        />
      </a>
      <div className="navbar-nav-content">
        <div className="navbar-nav-links">
          <a href="#hero" className="navbar-link2 nav-link">
            Home
          </a>
          <a href="#inhalte" className="navbar-link3 nav-link">
            Wie?
          </a>
          <a href="#OSS" className="navbar-link4 nav-link">
            OSS
          </a>
          <a href="#FAQ" className="navbar-link5 nav-link">
            FAQ
          </a>
        </div>
        <a href="#mail" className="navbar-link6">
          <div className="navbar-get-started get-started">
            <span className="navbar-text">Mail</span>
          </div>
        </a>
      </div>
      <div>
        <div className="navbar-container2">
          <Script
            html={`<script defer>
    /*
Mobile menu - Code Embed
*/

/* listenForUrlChangesMobileMenu() makes sure that if you changes pages inside your app, 
the mobile menu will still work*/

const listenForUrlChangesMobileMenu = () => {
    let url = location.href;
    document.body.addEventListener("click", () => {
        requestAnimationFrame(() => {
            if (url !== location.href) {
                runMobileMenuCodeEmbed();
                url = location.href;
            }
        });
    },
    true
    );
};

const runMobileMenuCodeEmbed = () => {
    // Mobile menu
    const mobileMenu = document.querySelector("#mobile-menu")

    // Buttons
    const closeButton = document.querySelector("#close-mobile-menu")
    const openButton = document.querySelector("#open-mobile-menu")

    // On openButton click, set the mobileMenu position left to -100vw
    openButton && openButton.addEventListener("click", function() {
        mobileMenu.style.transform = "translateX(0%)"
    })

    // On closeButton click, set the mobileMenu position to 0vw
    closeButton && closeButton.addEventListener("click", function() {
        mobileMenu.style.transform = "translateX(100%)"
    })
}

runMobileMenuCodeEmbed()
listenForUrlChangesMobileMenu()
</script>`}
          ></Script>
        </div>
      </div>
    </nav>
  )
}

Navbar.defaultProps = {
  rootClassName: '',
  brandingLogo: '/logo.png.svg',
}

Navbar.propTypes = {
  rootClassName: PropTypes.string,
  brandingLogo: PropTypes.string,
}

export default Navbar
